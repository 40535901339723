<template>
  <div>
    <!--  Title Create Page  -->
    <portal to="body-top">
      <h3
        id="create_page_title"
        class="text-center font-weight-bolder mb-1"
      >
        {{ $t(CREATE_PAGE_TITLE) }}
      </h3>
    </portal>
    <!-- Header Filter Status Bar  -->
    <quote-status-bar
      :item="{}"
      :quote-status-bar="getStatusBar()"
      style="margin-bottom: 1rem"
    />
    <!-- Body Form  -->
    <error-alert
      :fields="{}"
      :error="onError"
    />
    <b-form>
      <validation-observer
        ref="salesRentalQuoteForm"
      >
        <create-form
          ref="createForm"
          :is-update="false"
        />
      </validation-observer>
    </b-form>
    <!--  Footer Body  -->
    <portal to="body-footer">
      <form-bottom
        :is-update="false"
        :form-ref="$refs"
      />
    </portal>
  </div>
</template>

<script>

import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import { BForm } from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import FormBottom from '@/views/main/orders/view/rental-sales/components/create/components/FormBottom.vue'
import CreateForm from '../components/create/Form.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'RentalSalesCreate',
  components: {
    ErrorAlert,
    BForm,
    QuoteStatusBar,
    CreateForm,
    FormBottom,
    ValidationObserver,

  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    onError() {
      return this.$store.state[this.MODULE_NAME].onError
    },
  },
  created() {
    this.$store.dispatch('listModule/attachmentCategoryListAssets')
    this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_RENTAL_SALES_FORM_CLONE`, this.getTotalDataOfForms)
  },
  methods: {
    getStatusBar() {
      this.quoteStatusBar[1].isShow = false
      return this.quoteStatusBar
    },
  },
  setup() {
    const {
      CREATE_PAGE_TITLE, MODULE_NAME, MODULE_NAME_CLONE, quoteStatusBar, draftStatus,
    } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      CREATE_PAGE_TITLE,
      quoteStatusBar,
      draftStatus,
    }
  },
}
</script>
